// - - - Geograph - - - //

/*


Only buying the two weights...

MEDIUM      -   500
REGULAR		- 	400

*/

@font-face {
	font-family: 'geograph';
	src: url('./assets/fonts/geograph/geograph-web-regular.eot');
	src: url('./assets/fonts/geograph/geograph-web-regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/geograph/geograph-web-regular.woff2') format('woff2'), url('./assets/fonts/geograph/geograph-web-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: block;
	
}

@font-face {
	font-family: 'geograph';
	src: url('./assets/fonts/geograph/geograph-web-medium.eot');
	src: url('./assets/fonts/geograph/geograph-web-medium.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/geograph/geograph-web-medium.woff2') format('woff2'), url('./assets/fonts/geograph/geograph-web-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: block;
	
}

@font-face {
	font-family: 'geograph';
	src: url('./assets/fonts/geograph/geograph-web-regular-italic.eot');
	src: url('./assets/fonts/geograph/geograph-web-regular-italic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/geograph/geograph-web-regular-italic.woff2') format('woff2'), url('./assets/fonts/geograph/geograph-web-regular-italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: block;
	
}

@font-face {
	font-family: 'geograph';
	src: url('./assets/fonts/geograph/geograph-web-medium-italic.eot');
	src: url('./assets/fonts/geograph/geograph-web-medium-italic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/geograph/geograph-web-medium-italic.woff2') format('woff2'), url('./assets/fonts/geograph/geograph-web-medium-italic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: block;
	
}