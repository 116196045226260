
.inline-link {

	border-radius: 6px;

	div {
		display: inline-block;
		position: relative;
		transform: translateX( 0.0em );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
	}

	div::before {
		content: "";
		position: absolute;
		bottom: 0.45em;
		left: -1.0em;
		right: calc( 100% + 0.25em );
		transform-origin: right center;
		transform: scaleX( 0 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-black;
	}

	div::after {
		content: "";
		position: absolute;
		bottom: 0.45em;
		right: -1.0em;
		left: calc( 100% + 0.25em );
		transform-origin: left center;
		transform: scaleX( 1 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-black;
	}

}

body:not(.mobile) {

	.inline-link {

		&:hover {

			div {
				transform: translateX( 0.75em );
			}

			div::before {
				transform: scaleX( 1 );
			}

			div::after {
				transform: scaleX( 0 );
			}
		}

	}

}


.invert.blend-mode-clip {

	.inline-link {

		div::before {

		}

	}

}


body:not(.mobile) {

	.invert.blend-mode-clip {
	
		.inline-link {

			&:hover {

				div::before {

				}
			}
		}

	}

}


body:not(.mobile) {

	.focus.blend-mode-clip {

		.inline-link {

			&:hover {

				div::before {

				}
			}
		}

	}

}