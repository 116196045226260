// FONT

html {
    // 1rem = 10px
    font-size: 10px; // 1rem = 10px
}

body {
    @extend .geograph;
    line-height: 1.3;
    background-color: $br-white;
    color: $br-black;
    position: relative;

    user-select: none;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
}

.link {
    cursor: pointer;
}

a {
    display: inline-block;
}