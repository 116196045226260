// SITE

$site-width:  960px;
$site-gutter: 7.5%;

$site-border: 4rem;
$site-margin: 4rem;


$site-max-1: 140rem;
$site-max-2: 135rem;
$site-max-3: 52rem;
$site-max-4: 52rem;

// BREAKPOINTS

$breakpoint-1: 400px;
$breakpoint-2: 520px;
$breakpoint-3: 640px;
$breakpoint-4: 720px;
$breakpoint-5: 960px;
$breakpoint-6: 1140px;
$breakpoint-7: 1380px;


// COLORS

$br-black: 			#000;
$br-white: 			#fff;

$br-offblack: 		#444;
$br-darkgrey: 		#323232;
$br-medgrey: 		#bbb;
$br-offwhite: 		#eee;

$br-red: 			#E50B66;
$br-green: 			#1B835B;
$br-blue: 			#4B19D8;