.copied-popup {

	@extend .geograph;
	@extend .value-1;

	position: absolute;
	font-size: 1.5rem;

	width: 5em;
	height: 1.8em;
	background-color: $br-black;
	color: $br-white;

	top: 0;
	left: 0;
	opacity: 0;
	
	pointer-events: none;
	touch-action: none;

	display: flex;
	align-items: center;
	justify-content: center;

	div {

		position: relative;
		text-align: center;
		display: inline-block;
		z-index: 9999;

		&::before {
			content: "";
			width: 1em;
			height: 1em;
			background-color: $br-black;
			transform: translate( calc(-50% - 0.5em), 0.75em ) rotate( 45deg );
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: -1;
		}

	}

}