
.block-link {

	padding: 0.72em 1.8em 0.72em 0.96em;
	border-radius: 6px;
	border: 0.2rem solid $br-black;
	background-color: $br-white;

	div {
		display: inline-block;
		position: relative;
		transform: translateX( 0.0em );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		padding-right: 0.2rem;
	}

	div::before {
		content: "";
		position: absolute;
		bottom: 0.45em;
		left: -1.0em;
		right: calc( 100% + 0.25em );
		transform-origin: right center;
		transform: scaleX( 0 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-black;
	}

	div::after {
		content: "";
		position: absolute;
		bottom: 0.45em;
		right: -1.0em;
		left: calc( 100% + 0.25em );
		transform-origin: left center;
		transform: scaleX( 1 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-black;
	}

}

body:not(.mobile) {

	.block-link {

		&:hover {
			background-color: $br-black;
			color: $br-white;

			div {
				transform: translateX( 0.75em );
			}

			div::before {
				transform: scaleX( 1 );
				background-color: $br-white;
			}

			div::after {
				transform: scaleX( 0 );
				background-color: $br-white;
			}
		}

	}

}


.invert.blend-mode-clip {

	.block-link {
		background-color: $br-black;
		border-color: $br-white;

		div::before {
			background-color: $br-white;
		}

	}

}


body:not(.mobile) {

	.invert.blend-mode-clip {
	
		.block-link {

			&:hover {
				background-color: $br-white;
				color: $br-black;

				div::before {
				    background-color: $br-black;
				}
			}
		}

	}

}


body:not(.mobile) {

	.focus.blend-mode-clip {

		.block-link {

			&:hover {
				background-color: $br-white;
				color: $br-black;

				div::before {
				    background-color: $br-black;
				}
			}
		}

	}

}





@media screen and ( min-width: $breakpoint-1 ) {

	.block-link {



	}
	
}

@media screen and ( min-width: $breakpoint-2 ) {

	.block-link {



	}
	
}

@media screen and ( min-width: $breakpoint-3 ) {

	.block-link {

		padding: 0.82em 1.8em 0.78em 1.18em;

	}
	
}

@media screen and ( min-width: $breakpoint-4 ) {

	.block-link {



	}
	
}

@media screen and ( min-width: $breakpoint-5 ) {

	.block-link {

		padding: 0.88em 1.8em 0.88em 1.28em;

	}
	
}

@media screen and ( min-width: $breakpoint-6 ) {

	.block-link {



	}
	
}

@media screen and ( min-width: $breakpoint-7 ) {

	.block-link {



	}
	
}