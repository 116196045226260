.geograph {

	font-family: 'geograph';
	font-weight: 400;
	line-height: 1.2em;

	&.blurb {
		font-weight: 400;
		font-size: 1.3em;
	}  

	&.blurb-small {
		font-weight: 500;
		font-size: 1.1em;
	} 

	&.button {
		font-size: 1.2em;
		font-weight: 500;
		letter-spacing: 0.01em;
	}

	 &.icon-width {
		width: 3.2rem;
	}

	&.body {
		font-weight: 400;
		font-size: 1.3em;
		line-height: 1.7em;
	}  

	&.project-intro {
		font-size: 1.5em;
		line-height: 1.55em;
		font-style: italic;
	}

	&.subtitle-1 {

	}

	&.subtitle-1-bold {

	}

	&.subtitle-2 {

	}

	&.block-label {
		font-size: 1.4em;
		line-height: 1.2em;
		font-weight : 500;
		letter-spacing: 0.02em;
	}

	&.block-descr {
		font-size: 1.1em;
		line-height: 1.2em;
		font-weight : 400;
		letter-spacing: 0.02em;
	}

	&.block-label-alt {

	}

	&.inline-link {

	}

	&.callout-1 {
		// @extend .signifier;
		// @extend .blurb;
		font-style: italic;
	}

	&.body-h1 {
		// @extend .blurb;
		font-size: 1.8em;
		font-weight: 500;
	}

	&.body-h2 {
		font-size: 1.5em;
		font-weight: 500;
	}

	&.body-h3 {
		font-size: 1.5em;
		font-weight: 500;
	}

	&.body-h4 {
		font-size: 1.5em;
		font-weight: 500;
	}

	&.body-h5 {
		font-size: 1.5em;
		font-weight: 500;
	}

	&.body-h6 {
		font-size: 1.5em;
		font-weight: 500;
	}

	&.label-1 {
		font-size: 1.1em;
	}

	&.value-1 {
		font-size: 1.3em;
		font-weight: 500;
	}

	&.label-2 {
		font-size: 1.15em;
		letter-spacing: 0.01em;
	}

	&.value-2 {
		font-size: 1.15em;
		font-weight: 500;
		letter-spacing: 0.02em;
	}

	&.header-1 {

	}

	&.header-2 {

	}



}


.signifier {

	font-family: 'signifier';
	font-weight: 800;
	letter-spacing: 0.0em;
	line-height: 1.35em;
	
	&.headline {
		font-size: 3.0em;
		letter-spacing: 0.0em;
		line-height: 1.0em;
	}

}

em {
	// font-style: italic;
}



body { font-size: 1em; }

@media screen and ( min-width: $breakpoint-1 ) {
	
	$factor: 0.83;

	.geograph {

		&.blurb {
			// font-size: 1.3em;
		}  

		&.blurb-small {
			// font-size: 1.1em;
		}  

		&.button {
			// font-size: 1.2em;
		}

		&.icon-width {
			// width: 3.2rem;
		}

		&.body {
			// font-size: 1.3em;
		} 

		&.label-1 {
			font-size: 1.2em;
		}

		&.value-1 {
			font-size: 1.4em;
		}

		&.label-2 {
			font-size: 1.4em;
		}

		&.value-2 {
			font-size: 1.6em;
		}

		&.project-intro {
			// font-size: 1.5em;
		}

		&.block-label {
			// font-size: 1.45em;
		}

		&.block-descr {
			// font-size: 1.15em;
		}

	}

	.signifier {

		&.headline {
			font-size: 3.25em;
		}

	}

}

@media screen and ( min-width: $breakpoint-2 ) {

	.geograph {

		&.blurb {
			font-size: 1.5em;
		}  

		&.blurb-small {
			font-size: 1.15em;
		}  

		&.button {
			// font-size: 1.3em;
		}

		&.icon-width {
			// width: 3.4rem;
		}

		&.body {
			// font-size: 1.4em;
		} 

		&.label-1 {
			// font-size: 1.1em;
		}

		&.value-1 {
			// font-size: 1.3em;
		}

		&.label-2 {
			// font-size: 1.1em;
		}

		&.value-2 {
			// font-size: 1.3em;
		}

		&.project-intro {
			font-size: 1.7em;
		}

		&.block-label {
			// font-size: 1.45em;
		}

		&.block-descr {
			// font-size: 1.15em;
		}
	}

	.signifier {

		&.headline {
			font-size: 3.5em;
		}

	}

}

@media screen and ( min-width: $breakpoint-3 ) {

	.geograph {

		&.blurb {
			font-size: 1.6em;
		}  

		&.blurb-small {
			font-size: 1.35em;
		}  

		&.button {
			font-size: 1.3em;
		}

		&.icon-width {
			width: 3.5rem;
		}

		&.body {
			font-size: 1.35em;
		} 

		&.label-1 {
			font-size: 1.3em;
		}

		&.value-1 {
			font-size: 1.5em;
		}

		&.label-2 {
			font-size: 1.5em;
		}

		&.value-2 {
			font-size: 1.7em;
		}

		&.project-intro {
			font-size: 1.8em;
		}

		&.block-label {
			font-size: 1.45em;
		}

		&.block-descr {
			font-size: 1.15em;
		}
	}

	.signifier {

		&.headline {
			font-size: 3.75em;
		}

	}

}

@media screen and ( min-width: $breakpoint-4 ) {

	.geograph {

		&.blurb {
			font-size: 1.8em;
		}  

		&.blurb-small {
			// font-size: 1.3em;
		}  

		&.button {
			font-size: 1.4em;
		}

		&.icon-width {
			// width: 4.2rem;
		}

		&.body {
			// font-size: 1.3em;
		} 

		&.label-1 {
			// font-size: 1.1em;
		}

		&.value-1 {
			// font-size: 1.3em;
		}

		&.label-2 {
			// font-size: 1.1em;
		}

		&.value-2 {
			// font-size: 1.3em;
		}

		&.project-intro {
			font-size: 1.9em;
		}

		&.block-label {
			// font-size: 2.2em;
		}

		&.block-descr {
			// font-size: 1.3em;
		}

	}

	.signifier {

		&.headline {
			font-size: 4.25em;
		}

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.geograph {

		&.blurb {
			font-size: 1.9em;
		}  

		&.blurb-small {
			font-size: 1.4em;
		}  

		&.button {
			font-size: 1.5em;
		}

		&.icon-width {
			width: 3.8rem;
		}

		&.body {
			font-size: 1.5em;
		} 

		&.label-1 {
			font-size: 1.4em;
		}

		&.value-1 {
			font-size: 1.7em;
		}

		&.label-2 {
			font-size: 1.35em;
		}

		&.value-2 {
			font-size: 1.35em;
		}

		&.project-intro {
			font-size: 1.95em;
		}

		&.block-label {
			font-size: 1.5em;
		}

		&.block-descr {
			font-size: 1.2em;
		}

	}

	.signifier {

		&.headline {
			font-size: 4.85em;
		}

	}

}



@media screen and ( min-width: $breakpoint-6 ) {

	.geograph {

		&.blurb {
			font-size: 2.0em;
		}  

		&.blurb-small {
			font-size: 1.6em;
		}  

		&.button {
			font-size: 1.6em;
		}

		&.icon-width {
			// width: 5.2rem;
		}

		&.body {
			// font-size: 1.7em;
		} 

		&.label-1 {
			// font-size: 1.1em;
		}

		&.value-1 {
			// font-size: 1.3em;
		}

		&.project-intro {
			// font-size: 2.0em;
		}
	}

	.signifier {

		&.headline {
			font-size: 5.35em;
		}

	}

}

@media screen and ( min-width: $breakpoint-7 ) {

	.geograph {

		&.blurb {
			font-size: 2.1em;
		}  

		&.blurb-small {
			font-size: 1.75em;
		}  

		&.button {
			font-size: 1.7em;
		}

		&.icon-width {
			// width: 3.2rem;
		}

		&.body {
			font-size: 1.65em;
		} 

		&.label-1 {
			// font-size: 1.1em;
		}

		&.value-1 {
			// font-size: 1.3em;
		}

		&.project-intro {
			font-size: 2.1em;
		}

		&.block-label {
			font-size: 1.55em;
		}

		&.block-descr {
			// font-size: 1.2em;
		}
	}

	.signifier {

		&.headline {
			font-size: 5.75em;
		}

	}

}