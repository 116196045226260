$base-factor: 0.6;

body.section-home.loaded  {

	.blend-mode-clip {

		// - - - DEV for three/js canvas - - - 
		// opacity: 0.0;

	}
}


.section.home {

	@extend .clamp-width-2;

	// hidden site header
	.title-wrap {
		display: none;
	}


	.header {

		margin-bottom: 0.4em;
		
		p {
			@extend .signifier;
			@extend .headline;
		}

		p:last-child {

		    margin-top: 0.35em;
		    @extend .geograph;
		    @extend .blurb;
    	}

    	br { 
			display: none;
		}

	}

	.body-wrap {
		width: 100%;
	}

	.blurb {
		@extend .geograph, .blurb;
		max-width: 100rem;
	}

	.projects-link-wrap {
		margin-top: 1.6rem;
		margin-bottom: 2.0rem;
		min-width: 39%;
	
		&.deferring {
			.projects-link {
				display: none;
			}
			.defer-loader {
				display: inline-block;
			}
			.bar {
				display: inline-block;
			}
		}
	}
	
	.link {
		display: inline-block;
		width: 100%;
	}
	
	.defer-loader {
		
		display: none;			
		
		width: 100%;

		position: relative;

		@extend .block-link;
		@extend .geograph;
		@extend .button;
		
		div {
			// transition: none;
		}

		div::before {
			display: none;
		}

		div::after {
			display: none;
		}

		pointer-events: none;

		.bar {
			display: none;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $br-black;

			transform-origin: "0% 50%";
			transform: scaleX( 0 );
			
		}

		.label {
			color: $br-white;
			mix-blend-mode: difference;
		}
	}


	.projects-link {
		@extend .block-link;
		@extend .geograph;
		@extend .button;
	}

	.about-link {
		@extend .block-link;
		@extend .geograph;
		@extend .button;
		border-color: white;
		margin-top: 0.25rem;
	}

	// hidden site footer
	.footer-wrap {
		display: none;
	}

}


.blend-mode-inner>.header-wrap {

	color: $br-black;
	transition: color 0.2s linear;
	position: absolute;
	top: $site-margin * $base-factor;
	left: 0;
	right: 0;

	.title {
		@extend .geograph;
		@extend .blurb;
		overflow: hidden;
		padding-right: 2em;
		cursor: pointer;
		display: inline-block;
		margin-bottom: 0.125em;

		div {
			display: inline-block;
			position: relative;
			transform: translateX( 0.0em );
			transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		}

		div::before {
			content: "";
			position: absolute;
			bottom: 0.55em;
			left: -1.0em;
			right: calc( 100% + 0.25em );
			transform-origin: right center;
			transform: scaleX( 0 );
			transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
			height: 2px;
			background-color: $br-black;
		}

	}

	.subtitle {
		@extend .geograph;
		@extend .blurb;
		display: block;
		white-space: nowrap;
		position: relative;

		strong {
			@extend .signifier;
			font-size: 1.85em;
		}
	}

	.subtitle .page-header-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		p {
			white-space: normal;
		}
	}

}





body:not(.mobile) {

	.blend-mode-inner>.header-wrap {
		.title {

			&:hover {

				div {
					transform: translateX( 0.75em );
				}

				div::before {
					transform: scaleX( 1 );
				}

			}

		}

	}

}




.blend-mode-inner>.footer-wrap {

	position: absolute;
	bottom: $site-margin * $base-factor;
	left: 0;
	right: 0;

	.footer-wrap-inner {

		@extend .clamp-width-2;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.footer-icon {
			cursor: pointer;
			z-index: 9;
		}

		.github-icon,
		.linkedin-icon,
		.instagram-icon {
			@extend .geograph;
			@extend .icon-width;
			padding: 0.1em 0.3em 0.1em 0.3em;

			svg, img {
				min-width: 2.0rem;
				margin-top: 0.4rem;
			}
		}

		.email-icon {
			@extend .geograph;
			@extend .blurb-small;
			padding: 0.2em 0;
			cursor: pointer;
			z-index: 10;
		}

		&:hover {
			
				.footer-icon {
					opacity: 0.2;
				}

				.footer-icon:hover {
					opacity: 1;
				}

		}

	}

}



@media screen and ( min-width: $breakpoint-1 ) {

	$factor: 0.6;


	// - - - SITE MARGINS

	.section.home {

		.projects-link-wrap {
			margin-top: $site-margin * $factor;
		}
	}

	.blend-mode-inner>.header-wrap {
		top: $site-margin * $factor;
	}

	.blend-mode-inner>.footer-wrap {
		bottom: $site-margin * $factor;
	 }


}

@media screen and ( min-width: $breakpoint-2 ) {

	$factor: 0.6;

	.section.home {

		.projects-link-wrap {
			margin-top: 2.0rem;
		}

		.body-wrap {
			p:last-child {
			  
	    	}
		}

		.header {
			p:last-child {
		    	margin-top: 0.4em;
		    }
		}

	}

	.blend-mode-inner>.header-wrap {
		top: $site-margin * $factor;
	}

	.blend-mode-inner>.footer-wrap {
		bottom: $site-margin * $factor;
	}
}

@media screen and ( min-width: $breakpoint-3 ) {

	$factor: 0.8;

	.section.home {
		.projects-link-wrap {
			margin-top: 2.6rem;
		}
	}

	.blend-mode-inner>.header-wrap {
		top: $site-margin * $factor;
	}

	.blend-mode-inner>.footer-wrap {
		bottom: $site-margin * $factor;
	}

}

@media screen and ( min-width: $breakpoint-4 ) {

	$factor: 1.0;

	.section.home {

		.projects-link-wrap {
			min-width: 26rem;
			margin-top: 4.0rem;
		}

		.header {
			
			p:last-child {
		    	margin-top: 0.4em;
		    }

			br { 
				display: initial;
			}

		}

	}


	.blend-mode-inner>.header-wrap {
		top: $site-margin * $factor;
	}

	.blend-mode-inner>.footer-wrap {
		bottom: $site-margin * $factor;
	}
}

@media screen and ( min-width: $breakpoint-5 ) {
	
	.section.home {

		.projects-link-wrap {
			margin-top: 5.0rem;
		}

		.header {

			p:last-child {
				margin-top: 0.6em;
			}

		}

	}

}


@media screen and ( min-width: $breakpoint-6 ) {
	
	.section.home {

		.projects-link-wrap {
			margin-top: 6.0rem;
		}

		.header {

			p:last-child {
				// margin-top: 0.4em;
			}

		}

	}

}


@media screen and ( min-width: $breakpoint-7 ) {
	
	.section.home {

		.header {

			p:last-child {
				// margin-top: 0.4em;
			}

		}

	}

}