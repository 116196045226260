.focus-wrap {

	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: rgba( 0.0, 0.0, 0.0, 0.0 );
	z-index: 9999;

	display: none;

	pointer-events: none;
	opacity: 0;

	
	&.active {
		display: block;
	}

	&.visible {
		pointer-events: unset;
		opacity: 1;
	}

	.focus-inner {
		position: relative;
		width: 100%;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.loading-animation-wrap {
		display: none;
	}

	.focus-parent {
		z-index: 1;
		position:absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		// background-color: gray;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		span {
			cursor: grab;

			width: 100%;
			height: 100vh;
			overflow: hidden;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img,
			video,
			iframe {
				width: 100%;
				height: 100%;
				object-fit: contain;
				pointer-events: none;
			}

			&>div {
				width: 100%;
				height: 100vh;
				overflow: hidden;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		.video-outer { 
			width: 100%;
			flex-grow: 1;
		}

		.pinch-zoom-container {
			width: 100%;
			height: 100vh;
			overflow: hidden;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

	}
	

	.close-wrap {
		position: absolute;
		top: 0;
		right: $site-margin;
		height: $site-margin;
		color: $br-medgrey;
		z-index: 2;

		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;
		align-items: center;

		cursor: pointer;

	}

	.close-name {
		@extend .geograph;
		@extend .value-2;
		// margin-right: $site-margin;
	}

	.label-wrap {
		position: absolute;
		bottom: 0;
		left: $site-margin;
		height: $site-margin;
		color: $br-medgrey;
		z-index: 2;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.label-name {
		@extend .geograph;
		@extend .label-2;
	}

	.label-project {
		@extend .geograph;
		@extend .value-2;
	}

}