$border-ratio: 1.0;

.project.section {

	@extend .clamp-width;

	color: $br-darkgrey;

	$base-border-factor: 0.4;
	$base-margin-factor: 0.3;

	.intro>p, .text-container, h1, h2, h3, h4, h5, h6, ul, ol, code, hr {
	
		@extend .clamp-width-3;
		user-select: text;
		
	}

	.intro>p {
		margin-top: 0.8em;
		margin-bottom: 0.8em;
	}

	.text-container {
		margin-top: 0;
		margin-bottom: 0.8em;
	}

	ul { 
		margin-top: 1em;
		margin-bottom: 1.8em;
	}

	h1 {
		@extend .geograph;
		@extend .body-h1;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	h2 {
		@extend .geograph;
		@extend .body-h2;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	h3 {
		@extend .geograph;
		@extend .body-h3;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	h4 {
		@extend .geograph;
		@extend .body-h4;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	h5 {
		@extend .geograph;
		@extend .body-h5;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	h6 {
		@extend .geograph;
		@extend .body-h6;
		margin-top: 2.0em;
		margin-bottom: 0.5em;
	}

	blockquote {
		margin-top: 4em;
		margin-bottom: 4em;
	}

	blockquote p {
		@extend .callout-1;
		text-align: center;
		margin: 2.5em auto;
		padding: 0 1em;
	}

	// RESET for non-default p tags
	.image-container {

	}

	.image-container + .text-container {
		margin-top: 3rem;
	}

	.text-container + .image-container {
		margin-top: 3rem;
	}

	h2 + p {
		margin-top: 2.0em;
	}

	h2 + .image-container {
		margin-top: 6rem;
	}

	img, video {
		width: 100%;
		height: auto;
		max-width: initial;
	}

	.no-focus iframe {
		pointer-events: initial !important;
	}

	.img-wrap {
		position: relative;
		display: block;
		width: 100%;
		overflow: hidden;
		background-color: rgba( 0.0, 0.0, 0.0, 0.8 );

		cursor: pointer;

		iframe {
			background-color: $br-black;
		 	width: 100%;
			height: auto;
			display: none;
			pointer-events: none;
		}

		img, video {
			width: 100%;
			height: auto;
			font-size: 0em;
			display: block;
			user-select: none;
		}

		&:not(.loading) {
			background-color: unset;
			img {
				padding-bottom: 0 !important;
			}
			iframe {
				display: block !important;
			}
		}

		&.loading {
			img, video {
				opacity: 0;
			}
		}
	}

	.project-header {
		@extend .header-1;
	}

	.hero-wrap {
		@extend .clamp-width-4;
		margin-bottom: 1.2rem;
		position: relative;
		text-align: center;
	}

	.hero-wrap .image-container {

	}

	.hero-wrap .hero-inner {
		position: relative;
		margin: 0 auto;
		height: 40vw;
		width: 100%;
		padding: 0;
		max-height: calc( 100vh - 12rem );
		max-width: 140vh;
	}

	.hero-wrap.video-container {
		margin-top: 0 !important;
		background-color: rgba( 0.0,0.0,0.0,0.8 );
	}

	.hero-wrap .image-container .img-wrap {
		width: 100%;
		min-height: 56.25%;
		height: 56.25%;
		// padding-bottom: 56.25%;
		// background-size: cover;
		// background-position: 50% 50%;
	}

	.hero-wrap .image-container .img-wrap.loading {
	
	}

	.link-wrap {
		width: 100%;
		margin: 2rem auto;
	}

	.project-link {
		@extend .block-link;
	}

	.info-wrap {

		@extend .clamp-width-3;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 2rem;
		margin-top: 1rem;

	}

	.info-module {
		width: calc( 50% - 1rem );
		margin-bottom: 1.5em;
		margin-right: 1rem;

		&.wide  {
			width: 100%;
		}
	}

	.info-label {
		@extend .geograph;
		@extend .label-1;
		margin-bottom: 0.125em;
	}

	.info-value {
		@extend .geograph;
		@extend .value-1;

		&.multi {
			display: inline-block;
			margin-right: 0.15em;
			line-height: 1.5em;

			&::after{
				content: ",";
				display: inline-block;
				transform: translateX(-0.14em);
			}

			&:last-child::after {
				content: ""
			}
		}
	}


	.intro-wrap {
		width: 100%;
	}

	.intro {
		@extend .geograph;
		@extend .project-intro;
	}

	.project-body-wrap {
		width: 100%;
		line-height: 1.25em;
	}

	.project-body {
		p,
		&>ul,
		&>ol {
			@extend .geograph;
			@extend .body;
		}

		ul > li {
			margin-left: 2em;
		}

		li > ul {
			margin-left: 1em;
		}

		ol {

		}

		hr {
			width: 100%;
			border-top: solid 2px $br-black;
			@extend .clamp-width-2;
			margin-top: 4rem;
			margin-bottom: 4rem;
		}

		p code {
			padding: 2rem;
			background-color: $br-black;
			color: $br-white;
			border-radius: 4px;
			overflow: hidden;
			margin-top: 3rem;
			margin-bottom: 3rem;
			width: 100%;
			display: block;
		}

		ul {
			input[type='checkbox'] {
				pointer-events: none;
				accent-color: $br-black;
				background-color: $br-black;
			}
		}

		a {
			color: $br-blue;
			text-decoration: underline;
		}

	}

	.next-wrap {

		width: 100%;
		border-top: 2px solid $br-darkgrey;
		text-align: left;


		@extend .clamp-width-2;

		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 6rem;
		margin-bottom: 6rem;

		flex-direction: column;
	}

	.next-label {
		@extend .geograph;
		@extend .block-label;

		margin-top: 2em;
		margin-bottom: 1em;
	}

	.cue-block {
		width: 100%;
		text-align: left;

		.link {
			display: inline-block;
			margin: auto;
		}
	}

	.cue-block.back {
		margin-top: 0.5rem;
	}

	.next-project {

		@extend .geograph;
		@extend .project-link;
		
		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		padding-top: 4em;
		padding-bottom: 4em;
		padding-left: 2rem;
		padding-right: 4rem;


	}

	.project-name {
		@extend .geograph;
		@extend .block-label;
	}

	.project-desc {
		@extend .geograph;
		@extend .block-descr;
		span {
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
		}

	}

	.back-projects {
		width: 100%;
		padding-top: 0.85em;
		padding-bottom: 0.85em;
		@extend .project-link;
		@extend .block-label;
	}


	.video-container {

		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		margin: 1.2rem 0;
		overflow: hidden;

		video, iframe {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		    width: 100%;
			height: 100%;
		}
	}

	.video-container:not(.loading) {
		background-color: $br-white;
	}


	.img-wrap,
	.video-container {

		.loading-animation-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.loader-inner {
			position: relative;
			width: 100%;

			transform-origin: 50% 50%;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		.loader-dot {
			display: block;
			position: absolute;
			background-color: $br-white;
			width: 0.6rem;
			height: 0.6rem;
			position: absolute;
		}
	}

	.img-wrap.loading,
	.video-container.loading {
		.loading-animation-wrap {
			display: flex;			
		}
	}

	.img-wrap:not(.loading),
	.video-container:not(.loading) {
		.loading-animation-wrap {
			display: none;			
		}
	}

}


body.section-project {

	.blend-mode-inner>.header-wrap {
		color: $br-darkgrey;
	}

}





@media screen and ( min-width: $breakpoint-1 ) {

	$border-factor: 0.6;
	$margin-factor: 0.4;

	.project.section {

		.intro>p, .text-container, h1, h2, h3 {

		}

		.image-container {

			// margin-bottom: 1rem;
		}

		.video-container {
			margin: 1.5rem 0;
			padding-bottom: calc( 56.25% );
		}

		.cue-block {

		}

		.hero-wrap {
			margin: 0 auto 1.5rem auto;
		}

		blockquote p {
			margin: 3.2em auto;
			padding: 0 1em;
		}

		.image-container + .text-container {
			margin-top: 4rem;
		}

		.text-container + .image-container {
			margin-top: 4rem;
		}

	}

}

@media screen and ( min-width: $breakpoint-2 ) {

	$border-factor: 0.6;
	$margin-factor: 0.5;

	.project.section {

		.intro>p, .text-container, h1, h2, h3 {
			
		}

		.image-container {
			
			// margin-bottom: 1rem;
		}
		
		.video-container {
			margin: 2.0rem  0;
			padding-bottom: calc( 56.25% );
		}

		.next-wrap {
			margin-top: 8rem;
			margin-bottom: 8rem;
		}
		.cue-block.back .cue-label { 
			
		}
		.cue-block {

		}
		.cue-block.back {
			
		}

		.video-container {
			
		}

		.hero-wrap {
			margin: 0 auto 2rem auto;
		}
	}

}

@media screen and ( min-width: $breakpoint-3 ) {

	$border-factor: 0.8;
	$margin-factor: 0.8;

	.project.section {

		.intro>p, .text-container, h1, h2, h3 {
			
		}

		.image-container{
			// margin-bottom: 1rem;
		}

		.video-container {
			margin: 2.25rem 0;
			padding-bottom: calc( 56.25% );
		}

		.video-container {
		
		}

		.hero-wrap {
			margin: 0 auto 2.5rem auto;
		}

		.next-wrap {
			margin-top: 4rem;
			margin-bottom: 4rem;
		}

		.cue-block.back {

		}

		.back-projects {

		}

		.next-project {

		}

		.info-label {
			margin-bottom: 0.25em;
		}

	}

}

@media screen and ( min-width: $breakpoint-4 ) {

	$border-factor: 1.0;
	$margin-factor: 1.0;

	.project.section {

		.intro>p, .text-container, h1, h2, h3 {
			// margin: 4em auto;
		}

		.image-container {
			margin-bottom: 1rem;
		}

		.video-container {
			margin: 2.5rem 0;
			padding-bottom: calc( 56.25% );
		}

		.cue-block {

		}

		.video-container {

		}

		.hero-wrap {

		}

		
	}

}

@media screen and ( min-width: $breakpoint-5 ) {
	
	$border-factor: 1.0;
	$margin-factor: 1.0;

	.project.section {

		.intro>p, .text-container, h1, h2, h3 {
			// margin: 4em 0;
		}

		blockquote p {
			padding: 0 2em;
		}

		.image-container {

			// margin-bottom: 0rem;
		}

		.video-container {
			margin: 2.75rem 0;
			padding-bottom: calc( 56.25% );
		}

		.cue-block {
			
		}

		.video-container {

		}

		.hero-wrap {

		}

		.text-container {
			

		}
	}

}


@media screen and ( min-width: $breakpoint-6 ) {
	
}


@media screen and ( min-width: $breakpoint-7 ) {
	
}