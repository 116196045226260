
.project-link {

	@extend .geograph;

	padding: 0.64em 1.8em 0.64em 1.2em;
	border-radius: 6px;
	border: 0.2rem solid $br-black;
	background-color: $br-black;
	color: $br-white;


	.project-name {
		display: inline-block;
		max-width: 100%;
		position: relative;
		transform: translateX( 0.0em );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		
		span {
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
		}

	}

	.project-name::before {
		content: "";
		position: absolute;
		bottom: 0.5em;
		left: -1.0em;
		right: calc( 100% + 0.25em );
		transform-origin: right center;
		transform: scaleX( 0 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-white;
	}

	.project-name::after {
		content: "";
		position: absolute;
		bottom: 0.5em;
		right: -1.0em;
		left: calc( 100% + 0.25em );
		transform-origin: left center;
		transform: scaleX( 1 );
		transition: transform 0.25s cubic-bezier(0.15, 0, 0.0, 1);
		height: 0.2rem;
		background-color: $br-white;
	}

	.project-desc {

		span {
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
		}

	}

}


.project-link {

	&.hover {
		background-color: $br-offwhite;
		color: $br-black;
		border: 0.2rem solid $br-black;

		.project-name {
			transform: translateX( 0.75em );
		}

		.project-name::before {
			transform: scaleX( 1 );
			background-color: $br-black;
		}

		.project-name::after {
			transform: scaleX( 0 );
			background-color: $br-black;
		}
	}

}


.no-blend.blend-mode-clip {

	.project-link {

		border: 0.2rem solid $br-black;
		background-color: $br-white;
		color: $br-black;

		.project-name::before {
			background-color: $br-black;
		}
		.project-name::after {
			background-color: $br-black;
		}

		&.back-projects {
			border: 0.2rem solid $br-white;

			&:hover {
				border: 0.2rem solid $br-black;				
			}
		}

		&.hover {
			background-color: $br-black;
			color: $br-white;

			.project-name::before {
				background-color: $br-white;
			}

			.project-name::after {
				background-color: $br-white;
			}

		}

		&.back-projects.hover {
			border: 0.2rem solid $br-black;
		}

	}

}



.invert.blend-mode-clip {

	.project-link {

		&.hover {
			background-color: $br-black;
			color: $br-white;

			.project-name::before {
			    background-color: $br-white;
			}
		}
	}

}


.focus.blend-mode-clip {

	.project-link {

		&.hover {
			background-color: $br-black;
			color: $br-white;

			.project-name::before {
			    background-color: $br-white;
			}
		}
	}

}