.projects.section {

	.projects-wrap {

		// @extend .clamp-width;
		width: calc( 100% - #{ $site-margin * 2.0 * 0.6 } );
	// max-width: $site-max-2;
		margin-left: auto;
		margin-right: auto;

		margin-top: 2rem;
		margin-bottom: 2rem;

	}
	


	.project-module {

		@extend .geograph;
		@extend .project-link;

		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		padding-top: 6em;
		padding-bottom: 6em;
		padding-left: 2rem;
		padding-right: 4rem;

	}

	.project-name {

		@extend .geograph;
		@extend .block-label;


	}

	.project-desc {

		@extend .geograph;
		@extend .block-descr;

	}
	
}

body:not(.mobile) {

	.projects.section {
		.project-module {

			&:hover {

			}

		}
	}



}



@media screen and ( min-width: $breakpoint-1 ) {
		
	

}

@media screen and ( min-width: $breakpoint-2 ) {
	
	.projects.section {

		.projects-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

		}

		.project-module {
			width: calc( 50% - 0.5rem );
			align-self: stretch;
			
			&:nth-child( even ) {
				margin-left: 1rem;
			}
		}

		.project-name {

		}

		.project-desc {

		}
		
	}

}

@media screen and ( min-width: $breakpoint-3 ) {
	
	
	.projects.section {

		.projects-wrap {

			.project-module {
				padding-top: 8em;
				padding-bottom: 8em;
			}

		}

	}

}

@media screen and ( min-width: $breakpoint-4 ) {
	
}

@media screen and ( min-width: $breakpoint-5 ) {
		
	.projects.section {

		.projects-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.project-module {
				width: calc( 33.333% - 1.0rem );

				&:nth-child( 3n - 2 ) {
					margin-left: 0;
				}

				&:nth-child( 3n - 1 ) {
					margin-left: 1rem;
				}

				&:nth-child( 3n ) {
					margin-left: 1rem;
				}

				padding-top: 9.5em;
				padding-bottom: 9.5em;

			}

			.project-name {

			}

			.project-desc {

			}
		
		}

	}


}


@media screen and ( min-width: $breakpoint-6 ) {
	
	.projects.section {
		.projects-wrap {
			.project-module {

				padding-top: 11em;
				padding-bottom: 11em;

			}
		}
	}

}


@media screen and ( min-width: $breakpoint-7 ) {
	
	.projects.section {

		.projects-wrap {
			.project-module {

				padding-top: 12em;
				padding-bottom: 12em;

			}
		}
	}

}