body>.background {
	z-index: 0;

	// FLOAT to top for webgl dev
	// z-index: 10;
}

.blend-mode-wrap {
	z-index: 1;
}

.blend-mode-inner>.header-wrap {
	z-index: 3;
}

.blend-mode-inner>.footer-wrap {
	z-index: 3;
}

.overlay-wrap {
	z-index: 4;
}

.img-wrap,
.video-container {
	.loading-animation-wrap {
		z-index: 3;
	}
}

.blend-mode-wrap>.footer-wrap {
	.footer-icon {
		z-index: 5;
	}
	.email-icon {
		z-index: 6;
	}
}