.about.section {

	@extend .clamp-width;


	.about-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		// flex-wrap: wrap;
		margin-bottom: 2rem;
		margin-top: 1rem;


		.image-container {
			@extend .clamp-width-2;
			width: 100%;
			margin-bottom: 3rem;
		}

		.skinny {
			@extend .clamp-width-3;
		}

		.intro {
			@extend .clamp-width-3;
			width: 100%;

			@extend .geograph;
			@extend .project-intro;
			margin-bottom: 2rem;
		}


		img {
			width: 100%;
			height: auto;
			max-width: initial;
		}

		.img-wrap {
			position: relative;
			display: block;
			width: 100%;
			padding-bottom: 56.25%;
			overflow: hidden;
			background-color: rgba( 0.0,0.0,0.0,0.8 );

			img {
				cursor: pointer;
				position: absolute;
				width: 100%;
				height: auto;
				font-size: 0em;
				display: block;
				opacity: 0;
			}

			&:not(.loading) {

				img {
					opacity: 1;
					padding-bottom: 0 !important;
				}
			}

			.loading-animation-wrap {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.loader-inner {
				position: relative;
				width: 100%;

				transform-origin: 50% 50%;

				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
			}

			.loader-dot {
				display: block;
				position: absolute;
				background-color: $br-white;
				width: 0.6rem;
				height: 0.6rem;
				position: absolute;
			}

		}

		.img-wrap.loading {
			.loading-animation-wrap {
				display: flex;			
			}
		}

		.img-wrap:not(.loading) {
			.loading-animation-wrap {
				display: none;			
			}
		}

		.about-blurb {
			@extend .geograph;
			@extend .body;
			// line-height: 1.2em;
			margin-bottom: 3rem;

			p {
				margin-bottom: 1.5em;
			}

			a {
				text-decoration: underline;
				color: $br-blue;
			}
		}

		.list-wrap {

			width: 100%;
			margin-bottom: 2.0em;

			&.technologies {

			}

			&.awards {

			}

			&.facts {
				margin-bottom: 3.0rem;
			}

		}

		.list-item {
			display: block;
			line-height: 1.35em;

			&.multi {
				display: inline-block;
				margin-right: 0.12em;

				&::after{
					content: ",";
					display: inline-block;
					transform: translateX(-0.14em);
					// position: absolute;
				}

				&:last-child::after {
					content: ""
				}
			}

			&.link {
				@extend .geograph;
				@extend .value-1;
				@extend .inline-link;
				display: inline-block;
				border: none;
			}
		}

		.list-header {
			@extend .geograph;
			@extend .label-1;
			margin-bottom: 0.6rem;
		}

		.list-item {
			@extend .geograph;
			@extend .value-1;
		}

	}

}


@media screen and ( min-width: $breakpoint-1 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}


@media screen and ( min-width: $breakpoint-2 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}


@media screen and ( min-width: $breakpoint-3 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}


@media screen and ( min-width: $breakpoint-4 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}


@media screen and ( min-width: $breakpoint-5 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}



@media screen and ( min-width: $breakpoint-6 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}



@media screen and ( min-width: $breakpoint-7 ) {
		
	.about.section {

		.about-wrap {


		}

	}

}