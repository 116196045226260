// - - - Signifier - - - //

/*


Only buying the one weight...

BLACK        -   800


*/

@font-face {
    font-family: 'signifier';
    src: url('./assets/fonts/signifier/signifier-web-black.eot');
	src: url('./assets/fonts/signifier/signifier-web-black.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/signifier/signifier-web-black.woff2') format('woff2'), url('./assets/fonts/signifier/signifier-web-black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}