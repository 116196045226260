body {
	overflow-x: hidden;
	overflow-y: hidden;
}

.perf-wrap {
	display: none;

	position: absolute;
	bottom: 0;
	right: 0;
	background-color: black;
	width: 150px;
	height: 60px;
	color: white;
	opacity: 1.0;
	text-align: left;
	padding: 2rem 1rem;
}

.broadway-wrap {
	display: none;
	
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: black;
	width: 320px;
	height: 180px;
	opacity: 1.0;

	canvas {
		width: 100%;
		height: auto;
	}
}


.prevent-widows {
	display: inline-block;
}

.container {
	width: 100%;
	position: relative;
	min-height: calc( 100vh - 4rem );
}

.container-inner {

	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: calc( 100vh - #{$site-border*0.6*2.0} );
	top: 0;
	left: 0;
	opacity: 1;

	&.animate-in {
		opacity: 0;
	}

	&.animate-out {

	}

}

.loading-modal {

	&.loaded {
		display: none;
	}

}

.section {
	padding-top: 2 * $site-margin * 0.5 * 2.25;
	padding-bottom: 2 * $site-margin * 0.75;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.page-header{
	display: none;
}





body {
	overflow: hidden;
	position: fixed;
}

body>.background,
.overlay-wrap {
	position: fixed;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100vw;
	overflow: hidden;
	top: 0;
	left: 0;

	pointer-events: none;
	touch-action: none;
}

.overlay-wrap {

}

body>.background {
	background-color: $br-white;
	pointer-events: none;
	touch-action: none;
}





.blend-mode-parent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	top: 0;
	left: 0;

	// display: none;

}


.blend-mode-clip ::selection {
	background-color: #000000;
	color: #ffffff;
}

.loaded .blend-mode-clip {

	// - - - BG WORLD DEV - - - 
	// opacity: 0.0;

	width: 100vw;

	position: relative;
	overflow-x: hidden;
	overflow-y: scroll;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	background-color: $br-white;
	color: $br-black;
	border: solid $site-border $br-black;
	outline: solid $site-border $br-black;
	outline-offset: -1 * $site-border;

	box-sizing: border-box;

	-webkit-overflow-scrolling: touch;

	mix-blend-mode: screen;

	&.no-blend {
		mix-blend-mode: unset;
		border: solid 0px $br-white;
		margin: $site-border;
		width: calc( 100% - #{ $site-border * 0.8 } );
		outline: solid 0px $br-black;
		outline-offset: 0px;
		height: calc( 100vh - 4rem );
		height: calc( var(--vh, 1vh) * 100 - 4rem );

		color: $br-darkgrey;
	}
}


.blend-mode-wrap {

	position: relative;

	pointer-events: unset;
	touch-action: unset;

}


.blend-mode-inner {

}






.invert.blend-mode-clip { 

	background-color: $br-black;
	color: $br-white;

	&::selection {
	  background-color: $br-white;
	  color: $br-black;
	}

}


.blend-mode-inner>.header-wrap {
	@extend .clamp-width-2;
}


.focus.blend-mode-clip {

	outline-color: $br-white;
	background-color: $br-black;
	
	.blend-mode-inner>.header-wrap {
		color: $br-white;
	}

}

.section-projects .blend-mode-clip {

	.blend-mode-inner>.header-wrap {

	}
	
}



a.embedly-card {
	display: none;
}


// BORDER WIDTH FACTOR 

$base-factor: 0.6;

.container, .container-inner {
	min-height: calc( var(--vh, 1vh) * 100 - #{$site-border*$base-factor*2.0} );
}
.loaded .blend-mode-clip {
	border-width: $site-border*$base-factor;
	outline-width: $site-border*$base-factor;
	outline-offset: -1 * ($site-border*$base-factor);
	&.no-blend {
		border-width: 0px;
		margin: ($site-border*$base-factor);
		// max-height: calc( #{ $site-max-1 - ($site-border+$site-margin) * $base-factor } );
		width: calc( 100% - #{ $site-border * $base-factor * 2.0 } );
		outline-width: 0px;
		outline-offset: 0px;
		height: calc( var(--vh, 1vh) * 100 - #{$site-border*$base-factor*2} );
	}
}


.clamp-width-max {
	max-width: $site-max-1;
	max-height: $site-max-1;
	margin-right: auto;
	margin-left: auto;
}

.clamp-width {
	width: calc( 100% - #{ $site-margin * 2.0 * 0.6 } );
	max-width: $site-max-2;
	margin-left: auto;
	margin-right: auto;
}

.clamp-width-2 {
	width: calc( 100% - #{ $site-margin * 2.0 * 0.6 } );
	max-width: $site-max-3;
	margin-left: auto;
	margin-right: auto;
}

.clamp-width-3 {
	width: 100%;
	max-width: $site-max-4;
	margin-left: auto;
	margin-right: auto;
}

.clamp-width-4 {
	width: 100%;
	max-width: $site-max-1;
	margin-left: auto;
	margin-right: auto;
}


@media screen and ( min-width: $breakpoint-1 ) {


	$f: 0.6;
	.clamp-width {
		width: calc( 100% - #{ $site-margin * $f * 2.0 } );
	}

	.section {
		padding-top: 2 * $site-margin * 0.5 * 2.25;
		padding-bottom: 2 * $site-margin * 0.75;
	}

	$factor: 0.6;

	.container, .container-inner {
		min-height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
	}
	.loaded .blend-mode-clip {
		border-width: ($site-border*$factor);
		outline-width: ($site-border*$factor);
		outline-offset: -1 * ($site-border*$factor);
		&.no-blend {
			border-width: 0px;
			margin: ($site-border*$factor);
			width: calc( 100% - #{ $site-border * $factor * 2.0 } );
			height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
			outline-width: 0px;
			outline-offset: 0px;
		}
	}


}

@media screen and ( min-width: $breakpoint-2 ) {

	$f: 0.6;
	.clamp-width {
		width: calc( 100% - #{ $site-margin * $f * 2.0 } );
	}

	$factor: 0.6;
	.loaded .blend-mode-clip {
		border-width: ($site-border*$factor);
		outline-width: ($site-border*$factor);
		outline-offset: -1 * ($site-border*$factor);
		&.no-blend {
			border-width: 0px;
			margin: ($site-border*$factor);
			width: calc( 100% - #{ $site-margin * $factor * 2.0 } );
			outline-width: 0px;
			outline-offset: 0px;
			height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
		}
	}


	.section {
		padding-top: 2 * $site-margin * 0.5 * 2.25;
		padding-bottom: 2 * $site-margin * 0.75;
	}

	.prevent-widows {
		display: inline;
	}

}

@media screen and ( min-width: $breakpoint-3 ) {
	
	$f: 0.8;
	.clamp-width {
		width: calc( 100% - #{ $site-margin * $f * 2.0 } );
	}


	.section {
		padding-top: 2 * $site-margin * 0.92 * 1.5;
		padding-bottom: 2 * $site-margin * 0.92;
	}

	$factor: 0.8;

	.container, .container-inner {
		min-height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
	}
	.loaded .blend-mode-clip {
		border-width: ($site-border*$factor);
		outline-width: ($site-border*$factor);
		outline-offset: -1 * ($site-border*$factor);
		&.no-blend {
			border-width: 0px;
			margin: ($site-border*$factor);
			width: calc( 100% - #{ $site-margin * $factor * 2.0 } );
			outline-width: 0px;
			outline-offset: 0px;
			height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
		}
	}
	
}

@media screen and ( min-width: $breakpoint-4 ) {
	
	$f: 1.0;
	.clamp-width {
		width: calc( 100% - #{ $site-margin * $f * 2.0 } );
	}


	.section {
		padding-top: 2 * $site-margin * 1.12 * 1.5;
		padding-bottom: 2 * $site-margin * 1.12;
	}

	$factor: 1.0;

	.container, .container-inner {
		min-height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
	}
	.loaded .blend-mode-clip {
		border-width: ($site-border*$factor);
		outline-width: ($site-border*$factor);
		outline-offset: -1 * ($site-border*$factor);
		&.no-blend {
			border-width: 0px;
			margin: ($site-border*$factor);
			width: calc( 100% - #{ $site-margin * $factor * 2.0 } );
			// max-height: calc( #{ $site-max-1 - ($site-border)*$factor * 2 } );
			outline-width: 0px;
			outline-offset: 0px;
			height: calc( var(--vh, 1vh) * 100 - #{$site-border*$factor*2} );
		}
	}

	.clamp-width-2 {
		max-width: 49rem;
		// width: 65%;
	}

	.clamp-width-3 {
		max-width: 49rem;
	}

}

@media screen and ( min-width: $breakpoint-5 ) {
	
	.clamp-width-2 {
		max-width: 58rem;
		// width: 100%;
	}

}


@media screen and ( min-width: $breakpoint-6 ) {
	
	.clamp-width-2 {
		max-width: 68rem;
		// width: 45%;
	}

}


@media screen and ( min-width: $breakpoint-7 ) {
	
	.clamp-width-2 {
		max-width: 72rem;
		// width: 45%;
	}

}
